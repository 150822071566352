<template>
  <div class="demo">
    <h1 class="title">Assets Map</h1>

    <div class="element">
      <Switch label="TWO-STEP VERIFICATION"/>
    </div>
    <div class="element">
      <Button text="Default button"/>
    </div>
    <div class="element">
      <Button type="transparent" text="Transparent button"/>
    </div>
    <div class="element">
      <Button type="red" text="Delete"/>
    </div>
    <div class="element">
      <Input placeholder="Country name" value="USA" @onInput="onCompEvent('input', $event)"/>
    </div>
    <div class="element">
      <Input disabled value="USA is a great country" @onInput="onCompEvent('input', $event)"/>
    </div>
    <div class="element">
      <Input prepend="$" value="100" @onInput="onCompEvent('input', $event)"/>
    </div>
    <div class="element">
      <Textarea placeholder="Please, describe yourself" @onInput="onCompEvent('textarea', $event)"/>
    </div>
    <div class="element">
      <Checkbox label="Remember me"/>
    </div>
    <div class="element">
      <h1 class="header">HEADER 1</h1>
    </div>
    <div class="element">
      <h2 class="sub-header">HEADER 2</h2>
    </div>
    <div class="element">
      <div class="text">
        It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum
      </div>
    </div>
    <div class="element">
      <div class="text-italic">
        It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum
      </div>
    </div>
    <div class="element">
      <router-link to="/assets" class="link">Link 1</router-link>
    </div>
    <div class="element">
      <span class="icon">
        <img src="@/assets/images/icons/twitter.svg"/>
      </span>
      <span class="icon">
        <img src="@/assets/images/icons/instagram.svg"/>
      </span>
      <span class="icon">
        <img src="@/assets/images/icons/facebook.svg"/>
      </span>
      <span class="icon">
        <img src="@/assets/images/icons/apple.svg"/>
      </span>
      <span class="icon">
        <img src="@/assets/images/icons/google.svg"/>
      </span>
      <span class="icon">
        <img src="@/assets/images/icons/calendar.svg"/>
      </span>
    </div>
  </div>
</template>

<script>
  import {
    Button, Input, Textarea, Checkbox, Switch
  } from '../components/atoms';

  export default {
    name: 'Demo',
    components: {
      Button, Input, Textarea, Checkbox, Switch
    },
    setup() {
      const onCompEvent = (type, data) => {
        console.log(type, data);
      };

      return {
        onCompEvent,
      };
    }
  }
</script>

<style lang="scss" scoped>
  .demo {
    padding: 20px;
  }

  .title {
    margin: 20px;
    text-align: center;
  }

  .element {
    margin: 20px;
  }
</style>
