<template>
  <ul class="list d-flex justify-space-between">
    <li class="list__item text-center">
      <span class="list__item-value">{{ toSiFormat(statistics.followers, 1) }}</span>
      <div class="list__item-txt">Followers</div>
    </li>
    <li class="list__item text-center">
      <span class="list__item-value">{{ toSiFormat(statistics.subscribers, 1) }}</span>
      <div class="list__item-txt">Subscribers</div>
    </li>
    <li class="list__item text-center">
      <span class="list__item-value">{{ toSiFormat(statistics.videos, 1) }}</span>
      <div class="list__item-txt">Videos</div>
    </li>
    <li class="list__item text-center">
      <span class="list__item-value">{{ toSiFormat(statistics.photos, 1) }}</span>
      <div class="list__item-txt">Photos</div>
    </li>
  </ul>
</template>

<script>
  import { toSiFormat } from '@/utils';

  export default {
    name: 'ModelStatistics',
    props: {
      statistics: {
        followers: Number,
        subscribers: Number,
        videos: Number,
        photos: Number,
      }
    },
    setup() {
      return { toSiFormat }
    }
  }
</script>

<style lang="scss" scoped>
  .list {
    padding: 10px 28px 28px;

    list-style-type: none;
    font-family: Roboto;
    letter-spacing: 0.1em;

    &__item-value {
      font-style: normal;
      font-weight: 300;
      font-size: 9px;
      color: #E2F507;
    }

    &__item-txt {
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      color: #FFF;
      text-transform: uppercase;
    }
  }
</style>
