<template>
  <ul class="list justify-space-between attributes-list">
    <li class="list__item text-center">
      <span class="list__item-value">BUST</span>
      <div class="list__item-txt">{{ attributes.bust_size || '-' }}”</div>
    </li>
    <li class="list__item text-center">
      <span class="list__item-value">WAIST</span>
      <div class="list__item-txt">{{ attributes.waist_size || '-' }}”</div>
    </li>
    <li class="list__item text-center">
      <span class="list__item-value">HIPS</span>
      <div class="list__item-txt">{{ attributes.hip_size ? `${attributes.hip_size}”`  : '-' }}</div>
    </li>
    <li class="list__item text-center">
      <span class="list__item-value">HAIR LENGTH</span>
      <div class="list__item-txt">{{ attributes.hair_length || '-' }}</div>
    </li>
  </ul>

  <ul class="list justify-space-between attributes-list">
    <li class="list__item text-center">
      <span class="list__item-value">HEIGHT</span>
      <div class="list__item-txt">{{ height }}</div>
    </li>
    <li class="list__item text-center">
      <span class="list__item-value">WEIGHT</span>
      <div class="list__item-txt">{{ weight }}</div>
    </li>
    <li class="list__item text-center">
      <span class="list__item-value">EYES</span>
      <div class="list__item-txt">{{ attributes.eye_color || '-' }}</div>
    </li>
    <li class="list__item text-center">
      <span class="list__item-value">HAIR COLOR</span>
      <div class="list__item-txt">{{ attributes.hair_color || '-' }}</div>
    </li>
  </ul>

  <ul class="list justify-center attributes-list">
    <li class="list__item text-center">
      <span class="list__item-value">CUP SIZE</span>
      <div class="list__item-txt">{{ attributes.cup_size || '-' }}</div>
    </li>
  </ul>
</template>

<script>
  export default {
    name: 'ModelAttributes',
    props: {
      attributes: {
        bust_size: String,
        waist_size: String,
        hip_size: String,
        hair_length: String,
        weight: String,
        eye_color: String,
        hair_color: String,
        cup_size: String,
      },
      home_settings: {
        nationality: String,
      }
    },
    computed: {
      height() {
        const {attributes: { height }} = this;
        if (height) {
          if (height.includes('’') && height.includes('”')) {
            const inchesToCm = (height.match(/\d+/g)?.[1] || 0) * 2.54;
            const feetTocm = (height.match(/\d+/g)?.[0] || 0) * 30.48;
            const heightInCm = inchesToCm + feetTocm;
            return `${height} (${heightInCm | 0}cm)`;
          }

          return `${ height / 12 | 0 }' ${ height % 12 }" (${ height * 2.54 | 0}cm)`;
        }

        return '-';
      },
      weight() {
        const {attributes: { weight }} = this;
        if (weight) {
          const wdConverted = (weight * 0.45359237).toFixed(2);
          return `${weight} lbs (${wdConverted | 0}kg)`;
        }

        return '-';
      }
    }
  }
</script>

<style lang="scss" scoped>
  .list {
    padding: 28px;

    list-style-type: none;
    font-family: Roboto;
    letter-spacing: 0.1em;

    &__item-value {
      font-style: normal;
      font-weight: 300;
      font-size: 9px;
      color: #E2F507;
    }

    &__item-txt {
      font-style: normal;
      font-weight: bold;
      font-size: 10px;
      color: #FFF;
      text-transform: uppercase;
    }
  }

  .attributes-list {
    display: grid;
    grid-template-columns: 25% 25% 25% 25%;

    padding: 0 0 28px 0;
  }
</style>
