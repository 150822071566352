<template>
  <div class="status-bar text-center">
    <div class="status-bar__statuses">
      <ul class="status-bar__statuses-list">
        <li v-if="data.featured" class="status-bar__status status-bar__status--type-yellow">
          Featured
        </li>
        <li v-if="data.is_online" class="status-bar__status status-bar__status--type-green">
          Online
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'Statusbar',
    props: {
      data: Object,
    },
  }
</script>

<style lang="scss" scoped>
  .status-bar {
    padding-top: 10px;

    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 9px;

    letter-spacing: 0.3em;
    color: #FFFFFF;
    text-transform: uppercase;

    &__status {
      display: inline-block;
      padding: 2px 10px;
      margin: 0 6px;
      width: 90px;
    }

    &__status--type-yellow {
      background: #D5AF34;
    }

    &__status--type-green {
      background: #67CE67;
    }
  }
</style>
