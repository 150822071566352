<template>
  <div class="avatar d-flex">
    <div class="avatar__wrapper d-flex">
      <img class="avatar__spinner" src="@/assets/images/spinner.svg" v-if="isLoading"/>
      <img v-if="!thumbSrc" src="@/assets/images/avatar_default.png" class="avatar__img"/>
      <img v-else :src="thumbSrc" class="avatar__img" @error="onImageLoadError"/>

      <span class="icon" v-if="isEditable">
        <img src="@/assets/images/icons/photo.svg"/>
      </span>
      <input
        type="file"
        accept="image/*"
        class="avatar__file-btn"
        v-if="isEditable"
        @change="onTakePhoto"
      />
    </div>
  </div>
</template>

<script>
import { ref, watch } from 'vue';
  import { useStore } from 'vuex';
  import { v4 as uuidv4 } from 'uuid';
  // import loadImage from 'blueimp-load-image';
  import { common } from '@/api';
  import config from '@/config';

  export default {
    name: 'Avatar',
    props: {
      image: {
        type: String,
        default: ''
      },
      role: String,
      isEditable: Boolean,
      isModelRequest: {
        type: Boolean,
        default: false,
      }
    },
    setup(props) {
      const store = useStore();
      const thumbSrc = ref(props.image);
      const isLoading = ref(false);

      watch(() => props.image, (newImg) => {
        thumbSrc.value = '';

        /*
          Image on AWS can't be updated immediately event after callback so we need a delay on our side.
          TODO: find more elegant solution
        */
        setTimeout(() => {
          thumbSrc.value = newImg;
        }, config.AWS_REFRESH_FILES_DELAY);
      });

      const onTakePhoto = (e) => {
        const image = e.target.files[0];
        const data = {
          mediaType: 'avatar',
          contentType: image.type,
          fileName: image.name,
        };

        if (props.isModelRequest) data.role = 'model';

        let signedURLData = null;

        thumbSrc.value = URL.createObjectURL(image);
        isLoading.value = true;

        common.getS3UploadURL(data)
            .then(({ data }) => {
              signedURLData = data;

              return fetch(data.url, {
                method: 'PUT',
                mode: 'cors',
                body: image,
                headers: {
                  'Access-Control-Allow-Origin': '*',
                  'Content-Type': image.type,
                  'x-amz-acl': 'public-read'
                }
              })
            })
            .then(async () => {
              const payload = {
                mediaType: 'avatar',
                contentType: image.type,
                key: signedURLData.key
              }

              if (props.isModelRequest) payload.role = 'model';

              await common.reportForS3SuccessUpload(payload);

              await store.dispatch(
                `${props.isModelRequest ? 'consumer' : props.role}/updateProfileAvatar`,
                `${config.originBucket}/${signedURLData.key}?v=${uuidv4()}`
              );

              isLoading.value = false;
            })
            .catch((e) => {
              console.log(e);
              if (e.response.data) {
                alert(e.response.data.message);
              }
            });
      };

      const onImageLoadError = () => {
        thumbSrc.value = '';
      }

      return { thumbSrc, isLoading, onTakePhoto, onImageLoadError };
    }
  }
</script>

<style lang="scss" scoped>
  .avatar {
    display: flex;
    justify-content: center;

    &__wrapper {
      position: relative;

      width: 90px;
      height: 90px;

      border-radius: 45px;
      border: 5px solid rgba(0, 0, 0, .45);
    }

    &__img {
      width: 100%;
      height: 100%;
      object-fit: cover;

      border-radius: 40px;
      align-content: center;
    }

    .icon {
      position: absolute;
      bottom: 6px;
      right: -14px;

      height: 10px;
    }

    &__file-btn {
      position: absolute;
      width: 80px;
      height: 80px;

      opacity: 0;
    }

    &__spinner {
      position: absolute;
      width: 100%;

      border-radius: 40px;
    }
  }
</style>
